<template>
    <div style="background: #fff">
        <a-row>
            <a-col :span="18">
                <a-form layout="horizontal"
                        style="padding: 10px 30px; height: auto; white-space: nowrap">
                    <a-form-item label
                                 style="margin-bottom: 10px"
                                 :label-col="formItemLayout.labelCol"
                                 :wrapper-col="formItemLayout.wrapperCol">
                        <a-form layout="inline">
                            <a-form-item>
                                <label class="yj-partner-check_lab">支出类别：</label>
                                <a-radio-group default-value="-1" @change="radioChange">
                                    <a-radio-button value="-1">全部</a-radio-button>
                                    <a-radio-button value="003">佣金</a-radio-button>
                                    <a-radio-button value="016">奖励</a-radio-button>
                                    <a-radio-button value="012">充值</a-radio-button>
                                    <a-radio-button value="018">退费</a-radio-button>
                                </a-radio-group>
                            </a-form-item>
                        </a-form>
                    </a-form-item>
                    <a-form-item label
                                 style="margin-bottom: 0px"
                                 :label-col="formItemLayout.labelCol"
                                 :wrapper-col="formItemLayout.wrapperCol">
                        <a-form layout="inline">
                            <a-form-item label>
                                <label class="yj-partner-check_lab">单号：</label>
                                <a-input placeholder="订单号/交易号/微信支付单号"
                                         style="width: 200px"
                                         v-model="BillID" />
                            </a-form-item>
                            <a-form-item label="对方">
                                <a-input placeholder="姓名/手机"
                                         v-model="Name"
                                         style="width: 200px" />
                            </a-form-item>
                            <a-form-item label="时间">
                                <a-range-picker v-decorator="['range-picker', rangeConfig]"
                                                style="width: 260px"
                                                v-model="Time"
                                                @change="handleChange"></a-range-picker>
                            </a-form-item>
                            <a-form-item label>
                                <a-button type="primary" class="margin-r" @click="QueryData">查询</a-button>
                                <a-button type="primary" ghost @click="exportdata">导出</a-button>
                            </a-form-item>
                        </a-form>
                    </a-form-item>
                </a-form>
            </a-col>

            <a-col :span="6" style="padding:20px;text-align:center;">


                <small style="font-size:14px;color:#333"> 账户：</small><strong class="remain" style="color:green">{{ FinancialAccount.CashTotal }}</strong><small style="font-size:14px;color:#333">元</small>


            </a-col>
        </a-row>

        <a-layout-content style="background-color: #fff; overflow: hidden">
            <div class="yj_list"
                 style="padding: 0 20px 10px; min-height: calc(100vh - 502px)">
                <a-table :columns="columns"
                         bordered
                         :data-source="FinancialBills"
                         rowKey="ID"
                         :pagination="pagination"
                         @change="onPaginationChange">
                    <!--<span slot="DataStatus" slot-scope="text,record">
                                  {{ShowEnum(record.DataStatus)}}
                              </span>-->
                </a-table>
            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    import moment from "moment";
    export default {
        name: "billsdetail",
        data() {
            return {
                FinancialAccount: [], //账户财务信息
                b_OrderID: this.OrderID,
                columns: [
                    {
                        title: "流水号",
                        dataIndex: "BillID",
                    },
                    {
                        title: "用户",
                        width: 300,
                        customRender: (text, item) => {
                            var phone = item.ToAccountPhone;
                            var name = item.ToAccountName;
                            return (
                                <div>
                                    <div>{name}</div>
                                    <div>{phone}</div>
                                </div>
                            );
                        },
                    },
                    {
                        title: "转账单号",
                        dataIndex: "ProviderID",
                    },
                    {
                        title: "类别",
                        dataIndex: "BillType",
                    },
                    {
                        title: "金额",
                        customRender: (text, item) => {
                            var money = item.Money;
                            if (item.IOProjectID == "012") {
                                return <div style="color:#2281f8">+{money}</div>;
                            } else {
                                return <div style="color:red">-{money}</div>;
                            }
                        },
                    },
                    {
                        title: "余额",
                        customRender: (text, item) => {
                            var Balance = item.Balance;
                            var money = item.Money;
                            if (item.IOProjectID == "012") {
                                return <div style="color:green">{util.ToFixed(Balance * 1 + money * 1, "")}</div>;
                            } else {
                                return <div style="color:green">{util.ToFixed(Balance * 1 - money * 1, "")}</div>;
                            }
                        },
                    },
                    {
                        title: "时间",
                        dataIndex: "BillTime",
                    },
                    {
                        title: "摘要",
                        dataIndex: "Summary",
                    },
                    //, {
                    //    title: "数据状态",
                    //    dataIndex:"DataStatus",
                    //    scopedSlots: {customRender:"DataStatus"}
                    //}
                ],
                buyerDetail: [],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                config: {
                    rules: [
                        { type: "object", required: true, message: "Please select time!" },
                    ],
                },
                rangeConfig: {
                    rules: [
                        { type: "array", required: true, message: "Please select time!" },
                    ],
                },
                FinancialBills: [], //交易明细
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: (total) => `共 ${total} 条数据`,
                },
                isEmptyData: false, //数据是否为空
                BillType: "-1", //交易类别
                BeginTime: "",
                EndTime: "",
                Time: [],
                Name: "",
                BillID: "",
                //DataStatus:[] // 数据状态集合 0 1
            };
        },
        props: {
            // //组件属性
            OrderID: {
                type: String,
                default: "",
            },
        },
        methods: {
            GetFinancialAccount() {
                var self = this;
                var op = {
                    url: "/FinancialModule/Financial/FinancialAccount",
                    data: {
                        FinancalType: 1,
                    },
                    OnSuccess: function (res) {
                        if (res.data != []) {
                            res.data.CashTotal = parseFloat(res.data.CashTotal).toFixed(2);
                            res.data.PartnerCommission = parseFloat(
                                res.data.PartnerCommission
                            ).toFixed(2);
                            res.data.Recharges = parseFloat(res.data.Recharges).toFixed(2);
                            res.data.RewardMoney = parseFloat(res.data.RewardMoney).toFixed(2);
                            self.FinancialAccount = res.data;
                        }
                    },
                };
                http.Post(op);
            },
            GetDateTime() {
                var nDate = new Date();
                var CurrentDate =
                    nDate.getFullYear() +
                    "-" +
                    (nDate.getMonth() + 1) +
                    "-" +
                    nDate.getDate();
                var UpDate =
                    nDate.getFullYear() + "-" + nDate.getMonth() + "-" + nDate.getDate();
                this.BeginTime = UpDate;
                this.EndTime = CurrentDate;
                this.Time = [
                    moment(UpDate, "YYYY-MM-DD"),
                    moment(CurrentDate, "YYYY-MM-DD"),
                ];
            },
            radioChange(e) {
                this.BillType = e.target.value;
                this.pagination.current = 1;
                this.GetFinancialBills();
            },
            exportdata: function () {
                var self = this;
                var op = {
                    url: "/FinancialModule/Financial/GetUserFinancialExcel",
                    data: {
                        FinancalType: 1,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                        BillType: self.BillType,
                        Name: self.Name,
                        BillID: self.BillID,
                        PageIndex: self.pagination.current,
                        PageSize: 10,
                    }, //下载接口参数
                    FileName: "账户支出.xlsx", //下载文件重命名
                    ContentType:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //文件Mine类型
                };
                http.DownLoadFile(op);
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, fieldsValue) => {
                    if (err) {
                        return;
                    }
                    // Should format date value before submit.
                    const rangeValue = fieldsValue["range-picker"];
                    const rangeTimeValue = fieldsValue["range-time-picker"];
                    const values = {
                        ...fieldsValue,
                        "date-picker": fieldsValue["date-picker"].format("YYYY-MM-DD"),
                        "date-time-picker": fieldsValue["date-time-picker"].format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        "month-picker": fieldsValue["month-picker"].format("YYYY-MM"),
                        "range-picker": [
                            rangeValue[0].format("YYYY-MM-DD"),
                            rangeValue[1].format("YYYY-MM-DD"),
                        ],
                        "range-time-picker": [
                            rangeTimeValue[0].format("YYYY-MM-DD HH:mm:ss"),
                            rangeTimeValue[1].format("YYYY-MM-DD HH:mm:ss"),
                        ],
                        "time-picker": fieldsValue["time-picker"].format("HH:mm:ss"),
                    };
                    console.log(values);
                });
            },

            //获取账户交易明细信息
            GetFinancialBills() {
                var self = this;

                var op = {
                    url: "/FinancialModule/Financial/FinancialBills",
                    data: {
                        FinancalType: 1,
                        BeginTime: self.BeginTime,
                        EndTime: self.EndTime,
                        BillType: self.BillType,
                        Name: self.Name,
                        BillID: self.BillID,
                        PageIndex: self.pagination.current,
                        PageSize: 10,
                    },
                    OnSuccess: function (res) {
                        if (res.data.Data.length > 0) {
                            //self.getDataStatus();
                            self.isEmptyData = false;
                            res.data.Data.forEach((element) => {
                                element.Money = parseFloat(element.Money).toFixed(2);
                                element.BillTime = util.TimeStamptoDateTime(
                                    element.BillTime,
                                    "yyyy-MM-dd hh:mm:ss"
                                );
                                if (element.ToAccountPhone != null) {
                                    element.ToAccountPhone =
                                        element.ToAccountPhone.length != 11
                                            ? ""
                                            : element.ToAccountPhone;
                                }
                            });
                        } else {
                            self.isEmptyData = true;
                        }

                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;
                        self.FinancialBills = res.data.Data;
                    },
                };
                http.Post(op);
            },
            //跳页
            onPaginationChange(e) {
                this.pagination.current = e.current;
                this.GetFinancialBills();
            },
            //查看对方信息
            ToAccountDetail(e) {
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: {
                        ID: e,
                    },
                });
            },
            //查询
            QueryData() {
                this.pagination.current = 1;
                this.GetFinancialBills();
            },
            //时间选择监控事件
            handleChange(value) {
                if (value.length == 0) {
                    this.BeginTime = "";
                    this.EndTime = "";
                }
                this.Time = value;
                this.BeginTime = value[0]._d;
                this.EndTime = value[1]._d;
            },
            //获取数据状态
            getDataStatus() {
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.FinancialModule", //程序集名称
                        TypeName: "Welcome.FinancialModule.Enumerate.EnumDataStatus", //枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.DataStatus = response.data;
                    },
                };
                http.Post(op);
            },
            ShowEnum: function (Key) {
                //枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.DataStatus[Key]) return Self.DataStatus[Key].Name;
                else return "";
            },
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
            this.form = this.$form.createForm(this, { name: "time_related_controls" });
        },
        created: function () { },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            //this.GetDateTime();
            this.GetFinancialBills();
            this.GetFinancialAccount();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    };
</script>
<style scoped>
    .remain {
        padding-top: 25px;
        font-size: 34px;
        color: #2281f8;
        text-align: center;
    }
</style>
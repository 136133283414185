<template>
  <div >
 
      <a-layout style="height:100%;">
          <a-layout-content style="height:100%; ">
              <div class="yj-conten" style=" min-height: calc(100% - 48px);width:calc(100% -32px);min-width:max-content">
                  <a-layout style="height:100%;">
                      <!-- <a-layout-header style="background-color:#fff; margin-bottom:14px;height:auto;padding:0">
                          <accountdetail></accountdetail>
                      </a-layout-header> -->
                      <billsdetail></billsdetail>
                  </a-layout>
              </div>
          </a-layout-content>
      </a-layout>
    
  </div>
</template>
<script>
// import accountdetail from './components/accountDetail'
import billsdetail from './components/billsDetail'
export default {
  name: "FinancialModule_Expend",
  data() {
    return {
    };
  },
  props: {
    //组件属性
  },
  components:{
    billsdetail,
    // accountdetail
  },
  methods: {
  
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  beforeCreate: function() {
   
  },
  created: function() {
    //生命周期，创建完成
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>